import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Logo from "../../content/assets/logo-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebookSquare,
  faInstagram,
  faMedium,
} from "@fortawesome/free-brands-svg-icons"

const Layout = props => {
  const { title, children, currentLink } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  let hrefLink = "#"
  const linkClass = link =>
    link === currentLink ? "nav-item nav-current" : "nav-item"
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={hrefLink}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>

          <Link className="site-head-logo" to={`/`}>
            <div className="logo-block">
              <img src={Logo} className="logo bg-logo" alt="Trunkroad" />
              <div className="logo-text">TRUNKROAD</div>
            </div>
          </Link>
          <ul className="nav" role="menu">
            <li className={linkClass("/")} role="menuitem">
              <Link to={`/`}>Home</Link>
            </li>
            <li className={linkClass("/b2bcloud")} role="menuitem">
              <Link to={`/b2bcloud`}>Hub</Link>
            </li>
            <li className={linkClass("/mobilesuite")} role="menuitem">
              <Link to={`/mobilesuite`}>Mobile Store</Link>
            </li>
            <li className={linkClass("/salesrep")} role="menuitem">
              <Link to={`/salesrep`}>Sales Assistant</Link>
            </li>
            <li className={linkClass("/industries")} role="menuitem">
              <Link to={`/industries`}>Industries</Link>
            </li>
            <li className="nav-item" role="menuitem">
              <a
                href="https://medium.com/@trunkroad"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
            <li className={linkClass("/") + " high-res-only"} role="menuitem">
              <div className="centered text-column">
                <a href="#demo" className="button large">
                  Get a Demo!
                </a>
              </div>
            </li>
          </ul>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <article id="swup" className="transition-fade">
          {children}
        </article>
        <div id="demo" className="banner-bg row justify-content-center demo">
          <div className="col-6">
            <div className="sec-title text-center">
              <h3>Ready to talk? Get a demo today!</h3>
            </div>

            <div className="default-form flex flex-column">
              <form
                className="col-12"
                action="https://formspree.io/xvqaoelm"
                method="POST"
              >
                <div className="form-group input-group input-group-lg">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    required=""
                  />
                </div>

                <div className="form-group input-group input-group-lg">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    name="phone"
                    required=""
                  />
                </div>
                <div className="form-group input-group input-group-lg">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="_replyto"
                  />
                </div>

                <input
                  className="btn btn-block btn-lg btn-success"
                  type="submit"
                  value="Submit"
                />
              </form>
            </div>
          </div>
        </div>
      </main>
      <footer className="site-foot">
        <div className="footer-grid">
          <div>
            <div className="text-left">
              <p className="footer-title">Quick Links</p>
              <div className="footer-text">
                <p>
                  <a className="email-link" href="/about">
                    About
                  </a>
                </p>
                <p>
                  <a className="email-link" href="/industries">
                    Industries
                  </a>
                </p>
                <p>
                  <a className="email-link" href="#demo">
                    Contact
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="text-left">
              <p className="footer-title">Products</p>
              <div className="footer-text">
                <p>
                  <a className="email-link" href="/b2bcloud">
                    Hub
                  </a>
                </p>
                <p>
                  <a className="email-link" href="/mobilesuite">
                    Mobile Store
                  </a>
                </p>
                <p>
                  <a className="email-link" href="/salesrep">
                    Sales Assistant
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="text-left">
              <p className="footer-title">Support</p>
              <div className="footer-text">
                <p>
                  <a className="email-link" href="mailto:support@trunkroad.com">
                    support@trunkroad.com
                  </a>
                </p>
                <p>
                  <a className="email-link" href="tel:+917042850252">
                    +91 70428 50252
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="seperator-left" />
          <div>
            <h6 className="footer-title high-res-only">Connect</h6>
            <div className="social-container">
              <div className="social-box">
                <a
                  href="http://facebook.com/gotrunkroad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </h2>{" "}
                  Facebook
                </a>
              </div>
              <div className="social-box">
                <a
                  href="http://instagram.com/gotrunkroad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faInstagram} />
                  </h2>{" "}
                  Instagram
                </a>
              </div>
              <div className="social-box">
                <a
                  href="http://twitter.com/gotrunkroad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faTwitter} />
                  </h2>{" "}
                  Twitter
                </a>
              </div>
              <div className="social-box">
                <a
                  href="http://medium.com/@trunkroad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faMedium} />
                  </h2>{" "}
                  Medium
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
        </div>
      </footer>
    </div>
  )
}

export default Layout
